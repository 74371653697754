
import { defineComponent, ref, toRef } from "vue"
import Swal from "sweetalert2/dist/sweetalert2.js"
import { hideModal } from "@/core/helpers/dom"
import * as Yup from "yup"

import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"
import type { ElForm } from "element-plus"

type FormInstance = InstanceType<typeof ElForm>

export default defineComponent({
  name: "user-modal",
  components: {},
  props: [
    'add_users'
  ],
  setup(props) {
    const formRef = ref<FormInstance>()
    const store = useStore()
    const userModalRef = ref<null | HTMLElement>(null)

    const onSubmit = (formEl: FormInstance | undefined) => {
      console.log('onSubmit')

      let selected_items = tableData.value.filter((i) => {
        return (i as any).selected
      })

      props.add_users(selected_items)
      tableData.value = []
      hideModal(userModalRef.value)
    }

    const onCancel = () => {
      hideModal(userModalRef.value);
    }

    const users = ref([] as any)

    const formData = ref({
      nickname: "",
      mobile: "",
    })

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0
    })

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage
      query()
    }

    const onReset = () => {
      formData.value.nickname = '';
      formData.value.mobile = '';
    }

    const tableData = ref([])

    const query = () => {
      store
        .dispatch(Actions.GET_USERS, {
          ...formData.value,
          ...page.value,
        })
        .then(() => {
          tableData.value = store.getters.currentUsers
          page.value.totalResult = store.getters.currentUserCount
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const submitEvent = () => {
      query()
    }

    return {
      userModalRef,
      onSubmit,
      formRef,
      users,
      onCancel,
      formData,
      onPageChange,
      tableData,
      page,
      submitEvent,
      onReset,
    };
  },
});
