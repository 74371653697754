
import { defineComponent } from "vue";
import Form from "./Form.vue";

export default defineComponent({
  components: {
    Form,
  },
  setup() {
    return {};
  },
});
